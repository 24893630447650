<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>

  </div>
</template>
<script>
  export default {
    computed: {
      layout() {
        return this.$route.meta.layout ? `${this.$route.meta.layout}-layout` : 'default-layout'
      },
    }
  }
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
  }

#app {
  width: 100vw;
  height: 100vh;
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*text-align: center;*/
  /*color: #2c3e50;*/
}
.text_danger {
  font-size: 14px;
  color: #dc3545;
}
  .success_msg {
    font-size: 14px;
    color: green;
    font-weight: 500;
  }

/*#nav {*/
/*  padding: 30px;*/

/*  a {*/
/*    font-weight: bold;*/
/*    color: #2c3e50;*/

/*    &.router-link-exact-active {*/
/*      color: #42b983;*/
/*    }*/
/*  }*/
/*}*/
</style>
