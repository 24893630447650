<template>
    <div class="page_layer">
        <NavBar />
        <div class="page_content">
            <div class="page_card">
                <div class="card_title">Please sign in to get a quote</div>
                <form @submit.prevent="loginUser()">
                    <div class="input_item">
                        <label>Email</label>
                        <b-form-input
                                id="email"
                                v-model="userForm.email"
                                type="email"
                                placeholder="Type your email"
                                :class="{ 'is-invalid': isSubmitted && $v.userForm.email.$error }"
                                trim
                        >
                        </b-form-input>
                        <div v-if="isSubmitted && $v.userForm.email.$error" class="invalid-feedback">
                            <span v-if="!$v.userForm.email.required">Email field is required</span>
                            <span v-if="!$v.userForm.email.email">Please provide valid email</span>
                        </div>
                    </div>
                    <div class="input_item">
                        <label>Password</label>
                        <b-form-input
                                id="password"
                                :type="l_password"
                                v-model="userForm.password"
                                placeholder="Type your password"
                                :class="{ 'is-invalid': isSubmitted && $v.userForm.password.$error }"
                                trim
                        ></b-form-input>
                        <div :class="{'eye_close': l_password == 'password', 'eye_open': l_password == 'text',}"
                             @click="showPassword()"></div>
                        <div v-if="isSubmitted && $v.userForm.password.$error" class="invalid-feedback">
                            <span v-if="!$v.userForm.password.required">Password field is required</span>
                            <span v-if="!$v.userForm.password.minLength">Password should be at least 6 characters long</span>
                        </div>
                    </div>
                    <div class="forgot_container">
                        <div class="forgot_pass" id="forgot-btn" @click="forgotPassword()">Forgot password?</div>
                    </div>
                    <div v-if="loginError" class="text_danger">
                        <span>{{loginError}}</span>
                    </div>
                    <b-button class="sign_btn" type="submit">Sign In</b-button>

                    <div class="have_account">Don’t have an account ? <router-link to="/sign_up"><div class="sign_text">Sign Up</div></router-link> now</div>
                </form>

            </div>
        </div>
<!--forgot modal-->
        <b-modal ref="forgot-modal" centered size="md" hide-header hide-footer>
            <div class="close_btn_content" @click="forgotPassword()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#013F78" class="bi bi-x-lg"
                     viewBox="0 0 16 16">
                    <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
                </svg>
            </div>
            <div class="modal_content">
                <div class="modal_name">
                    Forgot password
                </div>
                <div class="forgot_text">
                    Enter your email address and we will send you a link to reset your password.
                </div>
                <div class="modal_input">
                    <div class="input_item form-group">
                        <b-form-input
                                id="forgot_email"
                                placeholder="Email"
                                v-model="forgot_email"
                                type="email"
                                trim
                        ></b-form-input>
                        <div class="mt-1">
                            <span v-if="forgotError" class="text_danger">{{forgotError}}</span>
                            <div v-if="successMsg" class="success_msg">{{successMsg}}</div>
                        </div>
                    </div>
                </div>
                <div class="modal_btn">
                    <b-button class="send_btn" @click="sendForgotPassword()">Send</b-button>
                </div>

            </div>

        </b-modal>
<!--forgot modal-->
    </div>
</template>

<script>
    import NavBar from "../NavBar";
    import {required, email, minLength} from "vuelidate/lib/validators";
    import {mapActions} from "vuex";
    export default {
        name: "SignIn",
        data() {
          return {
              userForm: {
                  email: "",
                  password: ""
              },
              forgot_email: '',
              forgotError: null,
              successMsg: null,
              l_password: 'password',
              isSubmitted: false,
              loginError: null,

          }
        },
        validations: {
            userForm: {
                email: {
                    required,
                    email
                },
                password: {
                    required,
                    minLength: minLength(6)
                },
            }
        },
        components: {
            NavBar
        },
        methods: {
            ...mapActions(['login', 'sendForgot']),
            loginUser() {
                this.isSubmitted = true;
                this.loginError = null
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                } else {
                    this.login(this.userForm)
                        .then((res) => {
                            if (res.status === 200) {
                                this.$router.push('/home')
                            } else {
                                this.loginError = res.response.data.error
                                // this.loginError = res.response.data.error
                            }
                        })
                }

            },
            showPassword() {
                if (this.l_password === 'password') {
                    this.l_password = 'text'
                } else {
                    this.l_password = 'password'
                }
            },
            forgotPassword() {
                this.forgot_email = ''
                this.$refs['forgot-modal'].toggle('#forgot-btn')
                this.forgotError = null
            },
            sendForgotPassword() {
                this.sendForgot(this.forgot_email)
                    .then((res) => {
                        if (res.status === 200) {
                            this.successMsg = res.data
                            setTimeout(() => {
                                this.successMsg = ''
                                this.$refs['forgot-modal'].toggle('#forgot-btn')
                            }, 4000)

                        } else {
                            this.forgotError = res.response.data.errors.email[0]
                        }
                    })
            }
        }
    }
</script>

<style scoped lang="scss">
.page {
    &_layer {
        height: calc(100% - 80px);
    }
    &_content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    &_card {
        width: 910px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px 0;
        & .card {
            &_title {
                font-weight: 500;
                font-size: 32px;
                line-height: 48px;
                margin-bottom: 24px;
            }
        }
        & form {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            & .forgot {
                &_container {
                    max-width: 440px;
                    width: 100%;
                    margin-bottom: 8px;
                }
                &_pass {
                    color: $main_blue;
                    font-size: 14px;
                    line-height: 17px;
                    font-weight: 400;
                    cursor: pointer;
                }
            }

            & .sign_btn {
                max-width: 440px;
                width: 100%;
                background: $main_blue;
                border-radius: 360px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 11px 14px;
                color: $white;
                line-height: 19px;
                margin: 22px 0;
                &:hover {
                    background: $main_hover;
                    border: 1px solid $main_hover;
                }
            }
        }
        & .input_item {
            position: relative;
            max-width: 440px;
            width: 100%;
            margin-bottom: 10px;

            & .eye_close {
                position: absolute;
                width: 16px;
                height: 16px;
                right: 15px;
                top: 44px;
                background-image: url("~@/assets/images/visibility closed.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                cursor: pointer;
            }

            & .eye_open {
                position: absolute;
                width: 16px;
                height: 16px;
                right: 15px;
                top: 44px;
                background: url("~@/assets/images/visibility_open.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                cursor: pointer;
            }
            & label {
                color: $black;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
            }

            & input {
                background: $white 0 0 no-repeat padding-box;
                border: 1px solid $main_grey;
                border-radius: 4px;
                max-width: 440px;
                width: 100%;
                height: 44px;
                &:focus {
                    box-shadow: none;
                    border: 1px solid $dark_grey;
                    border-radius: 4px;
                }
            }
        }
        & .have_account {
            display: flex;
            & .sign_text {
                color: $main_blue;
                margin: 0 6px;
            }
        }
    }
}

/*    */
.modal_content {
    padding: 5px 15px 15px;
    border: none;

    & .modal_name {
        font-size: 24px;
        font-weight: 500;
        color: $dark_grey;

    }

    & .forgot_text {
        padding: 10px 0 25px;
        font-size: 13px;
        color: $main_blue;
    }

    & .modal_input {
        padding-bottom: 20px;

        & .input_item {
            margin-bottom: 10px;

            & input {
                background: $white 0 0 no-repeat padding-box;
                border: 1px solid $main_grey;
                border-radius: 4px;
                height: 44px;
                &:focus {
                    box-shadow: none;
                    border: 1px solid $main_blue;
                    border-radius: 3px;
                }
            }
        }

        & .success_msg {
            color: green;
            font-size: 18px;
            font-weight: 600;
            margin-top: 10px;
        }
    }

    & .modal_btn {
        width: 100%;
        display: flex;
        justify-content: center;

        & .send_btn {
            background: $main_blue;
            width: 230px;
            height: 44px;
            border-radius: 360px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 11px 14px;
            color: $white;
            line-height: 19px;
            &:hover {
                background: $main_hover;
                border: 1px solid $main_hover;
            }

        }
    }
}

::v-deep .modal-content {
    border: none;
}

.close_btn_content {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    top: 20px;
    right: 25px;
    cursor: pointer;
}
</style>
