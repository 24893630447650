<template>
    <div class="page_layer">
        <NavBar/>
        <div class="page_content">
            <div class="page_card">
                <h1 v-if="success_msg" class="success_message">{{success_msg}}</h1>
                <div class="card_title">Please sign up to get a quote</div>
                <form @submit.prevent="signUp()">
                    <div class="input_item">
                        <label>Full Name <span> *</span></label>
                        <b-form-input
                                v-model="userForm.fullName"
                                type="text"
                                placeholder="Type your full name"
                                :class="{ 'is-invalid': isSubmitted && $v.userForm.fullName.$error }"
                                trim
                        >
                        </b-form-input>
                        <div v-if="isSubmitted && $v.userForm.fullName.$error" class="invalid-feedback">
                            <span v-if="!$v.userForm.fullName.required">Full Name field is required</span>
                        </div>
                    </div>
                    <div class="input_item">
                        <label>Company Name <span> *</span></label>
                        <b-form-input
                                v-model="userForm.companyName"
                                type="text"
                                placeholder="Type your company name"
                                :class="{ 'is-invalid': isSubmitted && $v.userForm.companyName.$error }"
                                trim
                        >
                        </b-form-input>
                        <div v-if="isSubmitted && $v.userForm.companyName.$error" class="invalid-feedback">
                            <span v-if="!$v.userForm.companyName.required">Company Name field is required</span>
                        </div>
                    </div>
                    <div class="input_item">
                        <label>Email <span> *</span></label>
                        <b-form-input
                                v-model="userForm.email"
                                type="email"
                                placeholder="Type your email"
                                :class="{ 'is-invalid': isSubmitted && $v.userForm.email.$error }"
                                trim
                        >
                        </b-form-input>
                        <div v-if="isSubmitted && $v.userForm.email.$error" class="invalid-feedback">
                            <span v-if="!$v.userForm.email.required">Email field is required</span>
                            <span v-if="!$v.userForm.email.email">Please provide valid email</span>
                        </div>
                    </div>
                    <div class="input_item">
                        <label>Phone Details <span> *</span></label>
                        <b-form-input
                                v-model="userForm.phone"
                                type="text"
                                placeholder="Type your phone details"
                                :class="{ 'is-invalid': isSubmitted && $v.userForm.phone.$error }"
                                trim
                        >
                        </b-form-input>
                        <div v-if="isSubmitted && $v.userForm.phone.$error" class="invalid-feedback">
                            <span v-if="!$v.userForm.phone.required">Phone Details field is required</span>
                        </div>
                    </div>
                    <div class="input_item">
                        <label>Password <span> *</span></label>
                        <b-form-input
                                :type="r_pass"
                                v-model="userForm.password"
                                placeholder="Type your password"
                                :class="{ 'is-invalid': isSubmitted && $v.userForm.password.$error }"
                                trim
                        ></b-form-input>
                        <div :class="{'eye_close': r_pass == 'password', 'eye_open': r_pass == 'text'}"
                             @click="showPassword('r_pass')"></div>
                        <div v-if="isSubmitted && $v.userForm.password.$error" class="invalid-feedback">
                            <span v-if="!$v.userForm.password.required">Password field is required</span>
                            <span v-if="!$v.userForm.password.minLength">Password should be at least 6 characters long</span>
                        </div>
                    </div>
                    <div class="input_item">
                        <label>Confirm password <span> *</span></label>
                        <b-form-input
                                :type="r_confirm"
                                placeholder="Type your confirm password"
                                v-model="userForm.password_confirmation"
                                :class="{ 'is-invalid': isSubmitted && $v.userForm.password_confirmation.$error }"
                                trim
                        ></b-form-input>
                        <div :class="{'eye_close': r_confirm == 'password', 'eye_open': r_confirm == 'text',}"
                             @click="showPassword('r_confirm')"></div>
                        <div v-if="isSubmitted && $v.userForm.password_confirmation.$error"
                             class="invalid-feedback">
                            <span v-if="!$v.userForm.password_confirmation.required">Confirm Password field is required</span>
                            <span v-else-if="!$v.userForm.password_confirmation.sameAsPassword">Passwords must match</span>
                        </div>
                    </div>
                    <span v-if="error_msg" class="text_danger">{{error_msg}}</span>
                    <b-button class="sign_btn" type="submit">Sign Up</b-button>
                    <div class="have_account">Don’t have an account ? <router-link to="/sign_in"><div class="sign_text">Sign In</div></router-link> now</div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import NavBar from "../NavBar";
    import { required, email, minLength, sameAs } from "vuelidate/lib/validators"
    import { mapActions } from "vuex";
    export default {
        name: "SignUp",
        data() {
            return {
                userForm: {
                    fullName: "",
                    last_name: "",
                    email: "",
                    password: "",
                    password_confirmation: "",
                },
                isSubmitted: false,
                r_pass: "password",
                r_confirm: "password",
                success_msg: '',
                error_msg: null
            }
        },
        components: {
            NavBar
        },
        validations: {
            userForm: {
                fullName: {
                    required
                },
                companyName: {
                    required
                },
                email: {
                    required,
                    email
                },
                phone: {
                    required
                },
                password: {
                    required,
                    minLength: minLength(6)
                },
                password_confirmation: {
                    required,
                    sameAsPassword: sameAs("password")
                }
            }
        },
        methods: {
            ...mapActions(['registerUser']),
            showPassword(item) {
                if (item === 'r_pass') {
                    if (this.r_pass === "password") {
                        this.r_pass = "text"
                    } else {
                        this.r_pass = "password"
                    }
                } else {
                    if (this.r_confirm === "password") {
                        this.r_confirm = "text"
                    } else {
                        this.r_confirm = "password"
                    }
                }
            },
            signUp() {
                this.isSubmitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                } else {
                    this.error_msg = null
                    this.registerUser(this.userForm)
                        .then((res) => {
                            if (res.status == 200) {
                                this.success_msg = res.data.success_message
                                setTimeout(() => {
                                    this.success_msg = ''
                                    this.$router.push({ path: "/confirm_email", query: { email: this.userForm.email, id: res.data.id} } )
                                }, 3000)
                            } else {
                                this.error_msg = res.response.data.error.email[0]
                            }
                        })
                }
            }
        }
    }
</script>

<style scoped lang="scss">
.page {
    &_layer {
    height: calc( 100% - 80px);
     }
    &_content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &_card {
        width: 910px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
        position: relative;
        & .success_message {
            position: absolute;
            top: 25%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 500px;
            height: 140px;
            box-shadow: 0 0 8px rgb(0 0 0 / 15%);
            border-radius: 16px;
            background: $white;
            color: green;
            z-index: 10;
        }
        & .card {
            &_title {
                font-weight: 500;
                font-size: 32px;
                line-height: 48px;
                margin-bottom: 20px;
            }
        }
        & form {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            & .sign_btn {
                max-width: 440px;
                width: 100%;
                background: $main_blue;
                border-radius: 360px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 11px 14px;
                color: $white;
                line-height: 19px;
                margin: 20px 0;
                &:hover {
                    background: $main_hover;
                    border: 1px solid $main_hover;
                }
            }
        }
        & .input_item {
            position: relative;
            max-width: 440px;
            width: 100%;
            margin-bottom: 10px;

            & .eye_close {
                position: absolute;
                width: 16px;
                height: 16px;
                right: 15px;
                top: 44px;
                background-image: url("~@/assets/images/visibility closed.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                cursor: pointer;
            }

            & .eye_open {
                position: absolute;
                width: 16px;
                height: 16px;
                right: 15px;
                top: 44px;
                background: url("~@/assets/images/visibility_open.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                cursor: pointer;
            }
            & label {
                color: $black;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                & span {
                    color: red;
                }
            }

            & input {
                background: $white 0 0 no-repeat padding-box;
                border: 1px solid $main_grey;
                border-radius: 4px;
                max-width: 440px;
                width: 100%;
                height: 44px;
                &:focus {
                    box-shadow: none;
                    border: 1px solid $dark_grey;
                    border-radius: 4px;
                }
            }
        }
        & .have_account {
            display: flex;
            & .sign_text {
                color: $main_blue;
                margin: 0 6px;
            }
        }
    }
}
</style>
