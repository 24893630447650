<template>
    <div class="map-container">
        <div id="map" class="basemap"></div>
        <div class="calculation-box">
            <p class="calculation-box_title">Your Selected Area</p>
            <div class="calculation-box_position">
                <div class="box_img position"></div>
                <div class="value">{{pointLongLat}}</div>
            </div>
            <div class="calculation-box_container">
                <div class="calculation-box_width">
                    <div class="box_img width"></div>
                    <div class="value">{{box_width}} <span v-if="box_width">m</span></div>
                </div>
                <div class="calculation-box_height">
                    <div class="box_img height"></div>
                    <div class="value">{{box_height}} <span v-if="box_height">m</span></div>
                </div>
            </div>
            <div class="calculation-box_position">
                <div class="box_img area"></div>
                <div id="calculated-area"></div>
            </div>
            <p>Area Selection Tools</p>
            <div class="calculation-box_check">
                <b-form-group
                >
                    <b-form-radio-group
                            v-model="selectedMode"
                            name="radio-sub-component"
                            buttons
                    >
                        <b-form-radio value="arrow" class="arrow" v-b-tooltip.hover title="Move map">
                            <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_291_1053)">
                                    <path d="M16 9H11V4H14L10 0L6 4H9V9H4V6L0 10L4 14V11H9V16H6L10 20L14 16H11V11H16V14L20 10L16 6V9Z" fill="#BEBEBE"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_291_1053">
                                        <rect width="20" height="20" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </b-form-radio>
                        <b-form-radio value="box" class="box" v-b-tooltip.hover title="Draw box">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 5C5.897 5 5 5.897 5 7V17C5 18.103 5.897 19 7 19H17C18.103 19 19 18.103 19 17V7C19 5.897 18.103 5 17 5H7ZM7 17V7H17L17.002 17H7Z" fill="#BEBEBE"/>
                            </svg>
                        </b-form-radio>
                        <b-form-radio value="polygon" class="polygon" v-b-tooltip.hover title="Draw polygon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.707 2.293C16.6143 2.19996 16.5041 2.12617 16.3828 2.07589C16.2614 2.0256 16.1313 1.99981 16 2H8C7.86866 1.99981 7.73857 2.0256 7.61724 2.07589C7.4959 2.12617 7.38571 2.19996 7.293 2.293L2.293 7.293C2.19996 7.38571 2.12617 7.4959 2.07589 7.61724C2.0256 7.73857 1.99981 7.86866 2 8V16C2 16.266 2.105 16.52 2.293 16.707L7.293 21.707C7.38571 21.8 7.4959 21.8738 7.61724 21.9241C7.73857 21.9744 7.86866 22.0002 8 22H16C16.266 22 16.52 21.895 16.707 21.707L21.707 16.707C21.8 16.6143 21.8738 16.5041 21.9241 16.3828C21.9744 16.2614 22.0002 16.1313 22 16V8C22.0002 7.86866 21.9744 7.73857 21.9241 7.61724C21.8738 7.4959 21.8 7.38571 21.707 7.293L16.707 2.293ZM20 15.586L15.586 20H8.414L4 15.586V8.414L8.414 4H15.586L20 8.414V15.586Z" fill="#BEBEBE"/>
                            </svg>
                        </b-form-radio>
                        <b-form-radio value="edit" class="edit" v-b-tooltip.hover title="Edit polygon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.045 7.401C19.423 7.023 19.631 6.521 19.631 5.987C19.631 5.453 19.423 4.951 19.045 4.573L17.459 2.987C17.081 2.609 16.579 2.401 16.045 2.401C15.511 2.401 15.009 2.609 14.632 2.986L4 13.585V18H8.413L19.045 7.401ZM16.045 4.401L17.632 5.986L16.042 7.57L14.456 5.985L16.045 4.401ZM6 16V14.415L13.04 7.397L14.626 8.983L7.587 16H6ZM4 20H20V22H4V20Z" fill="#BEBEBE"/>
                            </svg>
                        </b-form-radio>
                        <div @click="goGo()" class="upload_item btn btn-secondary" v-b-tooltip.hover title="Upload shapefile/kml">
<!--                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                <path d="M11 15H13V9H16L12 4L8 9H11V15Z" fill="#BEBEBE"/>-->
<!--                                <path d="M20 18H4V11H2V18C2 19.103 2.897 20 4 20H20C21.103 20 22 19.103 22 18V11H20V18Z" fill="#BEBEBE"/>-->
<!--                            </svg>-->
                        </div>

                    </b-form-radio-group>
                </b-form-group>
            </div>
              <div class="info_text"><span class="info_icon"></span>
                  <span id="infoText"></span>
<!--                  Click and drag on the viewer to move around-->
              </div>
            <b-button class="calculation-box_btn" @click="resetSettings()">Reset</b-button>

        </div>
    </div>
</template>

<script>
    import mapboxgl from "mapbox-gl";
    import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';
    import axios from 'axios'
    export default {
        data() {
            return {
                accessToken: 'pk.eyJ1IjoibWluZ3lhbjIxNCIsImEiOiJja2h4Y2xiajAwMTBsMnZuNHBzMjVmYjlsIn0.w7Wxvvi2i4olHVL2gE1zkQ',
                map: {},
                draw: {},
                pointLongLat: null,
                fileToShow: null,
                sendData: {
                    mapData: null,
                    area: null,
                    location: null
                },
                rounded_area: null,
                location: null,
                longLat: null,
                selectedMode: 'box',
                uploadItems: [],
                box_width: null,
                box_height: null
            };
        },
        watch: {
            selectedMode: {
                handler(val) {
                    if(val == 'polygon') {
                        this.draw.changeMode('draw_polygon');
                        this.box_width = null
                        this.box_height = null
                        const infoText = document.getElementById('infoText');
                        infoText.innerHTML = `<span>Click on the viewer to create the vertices of your shape (the polygon will automatically close)</span>`
                    } else if(val == 'box') {
                        this.draw.changeMode('draw_rectangle');
                        const infoText = document.getElementById('infoText');
                        infoText.innerHTML = `<span>Click and drag on the viewer to create the box</span>`
                    } else if(val == 'edit') {
                        const infoText = document.getElementById('infoText');
                        infoText.innerHTML = `<span>Click and drag the vertices to move them</span>`
                        // console.log(val, 'edit')
                        // this.draw.changeMode('direct_select');
                    } else if(val == 'arrow') {
                        const infoText = document.getElementById('infoText');
                        infoText.innerHTML = `<span></span>`

                        // console.log(val, 'arrow')
                        // this.draw.changeMode('direct_select');
                    } else if(val == 'upload') {

                    }
                }
            }
        },
        props: ['uploadedFile'],
        mounted() {
            this.readFile()
            const infoText = document.getElementById('infoText');
            infoText.innerHTML = `<span>Click and drag on the viewer to create the box</span>`
        },
        methods: {
            goGo() {
                this.$emit('uploadFile')
            },
            readFile() {
                if (this.uploadedFile) {
                    this.fileToShow = this.uploadedFile
                }
                this.createMap()
            },
            setArea() {
                const answer = document.getElementById('calculated-area');
                answer.innerHTML = `<span>${this.rounded_area}</span> m²`

            },
            resetSettings() {
                this.fileToShow = null
                this.selectedMode ='box'
                this.pointLongLat = null
                this.box_width = null
                this.box_height = null
                localStorage.removeItem('fileUploaded')
                const answer = document.getElementById('calculated-area');
                answer.innerHTML = ''
                this.createMap()
                setTimeout(() => {
                    if (this.fileToShow) {
                        this.map.fitBounds([-0.223779, 51.499360]);
                    }
                }, 1000)
            },
            createMap() {
                mapboxgl.accessToken = this.accessToken;
                this.map = new mapboxgl.Map({
                    container: "map",
                    // style: "mapbox://styles/mapbox/satellite-v9",
                    style: "mapbox://styles/mapbox/satellite-streets-v12",
                    center: [-0.223779, 51.499360],
                    zoom: 8,
                    displayControlsDefault: false,
                    controls: {
                        polygon: true,
                        trash: true,
                    },
                });
                const modes = MapboxDraw.modes;
                modes.draw_rectangle = DrawRectangle;
                this.draw = new MapboxDraw({
                    displayControlsDefault: false,
                    controls: {
                        polygon: true,
                        trash: true
                    },
                    modes: modes,
                    // defaultMode: 'draw_polygon'
                });
                setTimeout(() => {
                    this.draw.changeMode('draw_rectangle');
                }, 500)
                this.map.addControl(new mapboxgl.NavigationControl({showCompass: false}));
                this.map.addControl(this.draw);
                this.map.on('load', () => {
                    this.map.addSource('earthquakes', {
                        type: 'geojson',
                        data: this.fileToShow
                    });
                    this.map.addLayer({
                        'id': 'outline',
                        'type': 'line',
                        'source': 'earthquakes',
                        'layout': {},
                        'paint': {
                            'line-color': '#0080ff',
                            'line-width': 2.5
                        },
                    });
                    this.map.addLayer({
                        'id': 'maine',
                        'type': 'fill',
                        'source': 'earthquakes', // reference the data source
                        'layout': {},
                        'paint': {
                            'fill-color': '#0080ff', // blue color fill
                            'fill-opacity': 0.1
                        }
                    });
                    setTimeout(() => {
                        if (this.fileToShow) {
                            this.map.fitBounds(this.fileToShow.features[0].geometry.coordinates[0]);
                        }
                    }, 1000)
                })
                this.map.on('mousemove', (e) => {
                    this.pointLongLat = e.lngLat.lng.toFixed(6) + ', ' + e.lngLat.lat.toFixed(6)
                })
                this.map.on('draw.create', this.updateArea);
                this.map.on('draw.delete', this.updateArea);
                this.map.on('draw.update', this.updateArea);
            },
            // onFileChange(event) {
            //     const reader = new FileReader();
            //     reader.addEventListener('load', (event) => {
            //         this.uploadItems.push(JSON.parse(event.target.result))
            //     });
            //     reader.readAsText(event.target.files[0]);
            //     setTimeout(() => {
            //         this.addFile()
            //     }, 100)
            //
            // },
            // addFile() {
            //     this.fileToShow = this.uploadItems
            //     this.createMap()
            // },
            updateArea(e) {
                const draw = new MapboxDraw({
                    displayControlsDefault: false,
                    controls: {
                        polygon: true,
                        trash: true,
                    },
                    defaultMode: 'draw_polygon'
                });
                const data = this.draw.getAll();
                const answer = document.getElementById('calculated-area');
                if (data.features.length > 0) {
                    const area = turf.area(data);
                    if(this.selectedMode == 'box') {
                        const corner1 = turf.point(data.features[0].geometry.coordinates[0][0]);
                        const corner2 = turf.point(data.features[0].geometry.coordinates[0][1]);
                        const corner3 = turf.point(data.features[0].geometry.coordinates[0][2]);
                        const corner4 = turf.point(data.features[0].geometry.coordinates[0][3]);
                        const corner5 = turf.point(data.features[0].geometry.coordinates[0][4]);

                        const polygon = turf.polygon([[corner1.geometry.coordinates, corner2.geometry.coordinates, corner3.geometry.coordinates, corner4.geometry.coordinates, corner5.geometry.coordinates]]);
                        const bbox = turf.bbox(polygon);
                        const options = {units: 'kilometers'}
                        const width = turf.distance(turf.point([bbox[0], bbox[1]]), turf.point([bbox[2], bbox[1]]), options).toFixed(3) * 1000;
                        const height = turf.distance(turf.point([bbox[0], bbox[1]]), turf.point([bbox[0], bbox[3]]), options).toFixed(3) * 1000;
                        this.box_width = width
                        this.box_height = height
                    }

                    const km_area = (Math.round(area * 100) / 100);
                    this.rounded_area = km_area.toFixed(2) ;
                    this.longLat = data.features[0].geometry.coordinates[0][0]
                    this.getLocation()
                    this.sendData.area = this.rounded_area
                    this.sendData.mapData = data
                    answer.innerHTML = `<span>${this.rounded_area}</span> m²`
                    setTimeout(() => {
                        this.$emit('mapData', this.sendData)
                    }, 1000)
                } else {
                    answer.innerHTML = '';
                    if (e.type !== 'draw.delete')
                        alert('Click the map to draw a polygon.');
                }
            },
            getLocation() {
                axios.get(
                    `https://api.tiles.mapbox.com/v4/geocode/mapbox.places/${this.longLat[0]},${this.longLat[1]}.json?access_token=pk.eyJ1IjoibWluZ3lhbjIxNCIsImEiOiJja2h4Y2xiajAwMTBsMnZuNHBzMjVmYjlsIn0.w7Wxvvi2i4olHVL2gE1zkQ`
                )
                    .then(response => {
                        if(response) {
                            this.sendData.location = response.data.features[2].place_name
                        } else {
                            this.sendData.location = 'Location'
                        }
                    })
                    .catch(error => {
                        console.log(error, 'error')
                    })
            }

        },
    };
</script>

<style scoped lang="scss">
    .map-container {
        position: relative;
        width: 100%;
        height: 50vh;

        & .basemap {
            height: 100%;

        }
    }

    /*.mapboxgl-popup-content .list-group-item {*/
    /*    padding: 0.5rem 0.25rem;*/
    /*}*/
    /*.mapboxgl-popup-content .list-group-item .label {*/
    /*    padding-right: 0.5rem;*/
    /*    font-weight: bold;*/
    /*}*/
    ::v-deep .mapboxgl-ctrl-bottom-left {
        display: none;
    }

    ::v-deep .mapboxgl-ctrl-bottom-right {
        display: none;
    }

    .calculation-box {
        width: 320px;
        position: absolute;
        top: 32px;
        left: 32px;
        background-color: rgba(255, 255, 255, 0.9);
        padding: 15px;
        text-align: center;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media screen and (max-width: 1440px) {
            width: 290px;
            top: 15px;
            left: 15px;
        }
        @media screen and (max-width: 1280px) {
            width: 240px;
            top: 10px;
            left: 10px;
        }
        &_title {
            margin-bottom: 10px;
        }

        & .box_img {
            width: 34px;
            height: 34px;
            border-radius: 4px 0 0 4px;
            background: #6388AA;
            background-repeat: no-repeat;
            background-position: center;

            &.position {
                background-image: url("~@/assets/images/location.svg");
            }

            &.width {
                background-image: url("~@/assets/images/width.svg");
            }

            &.height {
                background-image: url("~@/assets/images/height.svg");
            }

            &.area {
                width: 50px;
                background-image: url("~@/assets/images/area.svg");
            }
        }

        &_position {
            width: 100%;
            height: 34px;
            border: 1px solid #BEBEBE;
            border-radius: 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 12px;
            margin: 8px 0;
            @media screen and (max-width: 1280px) {
                margin: 5px 0;
            }

        }

        &_container {
            /*padding: 8px 0;*/
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            @media screen and (max-width: 1280px) {
                flex-direction: column;
            }
        }

        &_width,
        &_height {
            width: 124px;
            height: 34px;
            border: 1px solid #BEBEBE;
            border-radius: 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 12px;
            margin: 8px 0;
            @media screen and (max-width: 1280px) {
                width: 100%;
                margin: 5px 0;
            }
        }

        &_check {
            @media screen and (max-width: 1280px) {
                & .form-group {
                    margin-bottom: 10px;
                }
            }
            label.btn {
                width: 34px;
                height: 34px;
                background: transparent;
                margin: 0 5px;
                border-radius: 4px !important;
                border-color: #BEBEBE;
                position: relative;
                &.active {
                    background: $main_blue;
                    border-color: $main_blue;
                    box-shadow: none;
                    outline: none;
                    &.focus {
                        box-shadow: none;
                        outline: none;
                    }
                    & svg {
                        fill: $white !important;
                        path {
                            fill: $white;
                        }
                    }
                }
                &:focus,
                &:active {
                    box-shadow: none;
                    outline: none;
                }
                & svg {
                    position: absolute;
                    margin: 0 auto;
                    top: 4px;
                    left: 4px;

                }
                /*&.arrow {*/
                /*    background-image: url("~@/assets/images/arrow.svg");*/
                /*    background-repeat: no-repeat;*/
                /*    background-position: center;*/
                /*}*/
                &.box {
                    background-image: url("~@/assets/images/box.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                }
                &.polygon {
                    background-image: url("~@/assets/images/polygon.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                }
                &.edit {
                    background-image: url("~@/assets/images/edit._grey.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                }
                &.upload_item {
                    background-image: url("~@/assets/images/upload.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    position: relative;
                    z-index: 1;
                    & input {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        cursor: pointer !important;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        z-index: 0;
                    }
                }
            }
            & .upload_item {
                width: 34px;
                height: 34px;
                background: transparent;
                margin: 0 5px !important;
                border-radius: 4px !important;
                border-color: #BEBEBE;
                background-image: url("~@/assets/images/upload.svg");
                background-repeat: no-repeat;
                background-position: center;
                position: relative;
                z-index: 1;
                & input {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    cursor: pointer !important;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    z-index: 0;
                }
            }
        }
        &_btn {
            max-width: 170px;
            width: 100%;
            background: $main_blue;
            border-radius: 360px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 11px 14px;
            color: $white;
            line-height: 19px;
            margin: 10px 0;
            &:active,
            &:focus {
                background: $main_blue;
            }
            &:hover {
                background: $main_hover;
                border: 1px solid $main_hover;
            }
        }
        & .info {
            &_text {
                display: flex;
                max-width: 250px;
                line-height: 20px;
                height: 60px;
                @media screen and (max-width: 1280px) {
                    font-size: 14px;
                    max-width: 195px;
                }
            }
            &_icon {
                width: 36px;
                height: 16px;
                background: url("~@/assets/images/info.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                margin-top: 5px;
                @media screen and (max-width: 1280px) {
                    margin-top: 3px;
                    width: 22px;
                }
            }
        }
    }

    #calculated-area {
        color: black;
        font-size: 18px;
    }
</style>
