<template>
    <div class="page_layer">
        <NavBar />
        <div class="page_content">
            <div class="page_card">
                <div class="not_found_img"></div>
                <h1 class="not_found_title">Page Not Found</h1>
                <p class="not_found_text">The page you are looking for may have been moved, deleted,
                    or possibly never existed</p>
                <b-button class="not_found_btn" @click="goBack()">Go Back</b-button>
            </div>
        </div>
    </div>
</template>

<script>
    import NavBar from "./NavBar";
    export default {
        name: "PageNotFound",
        components: {
            NavBar
        },
        methods: {
            goBack() {
                this.$router.go(-1)
            }
        }
    }
</script>

<style scoped lang="scss">
.page {
    &_layer {
        height: calc(100% - 80px);
    }
    &_content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    &_card {
        width: 910px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 100px 0;
        & .not_found {
            &_img {
                width: 320px;
                height: 120px;
                background: url("~@/assets/images/vector_404.svg") no-repeat center;
                background-size: cover;
            }
            &_title {
                font-weight: 500;
                font-size: 36px;
                line-height: 43px;
                color: $main_blue;
                padding: 48px 0;
            }
            &_text {
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                text-align: center;
                max-width: 530px;
                color: $dark_grey;
                margin-bottom: 48px;
            }
            &_btn {
                max-width: 440px;
                width: 100%;
                background: $main_blue;
                border-radius: 360px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 11px 14px;
                color: $white;
                line-height: 19px;
            }
        }
    }
}
</style>
