import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store"
import PageNotFound from "../components/PageNotFound";
import SignIn from "../components/Auth/SignIn";
import SignUp from "../components/Auth/SignUp";
import GentianDashboard from "../views/GentianDashboard";
import MapPage from "../views/MapPage";
import ChooseMap from "../views/ChooseMap";
import FileUpload from "../views/FileUpload";
import ConfirmEmail from "../components/ConfirmEmail";
import ForgotPassword from "../components/Auth/ForgotPassword";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: '/sign_in'
  },
  {
    path: "/sign_in",
    name: "SignIn",
    component: SignIn,
    meta: {auth: false}
  },
  {
    path: "/sign_up",
    name: "SignUp",
    component: SignUp,
    meta: {auth: false}
  },
  {
    path: "/confirm_email",
    name: "ConfirmEmail",
    component: ConfirmEmail,
    meta: {auth: false}
  },
  {
    path: '/forgot',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {auth: false}
  },
    //////////////////////////
  {
    path: '/home',
    name: 'MapPage',
    component: MapPage,
    meta: {layout: 'dashboard', auth: true},
    props: true
  },
  // {
  //   path: '/map',
  //   name: 'MapPage',
  //   component: MapPage,
  //   meta: {layout: 'dashboard', auth: true},
  //   props: true
  // },
  // {
  //   path: '/chooseMap',
  //   name: 'ChooseMap',
  //   component: ChooseMap,
  //   meta: {layout: 'dashboard', auth: true}
  // },
  // {
  //   path: '/fileUpload',
  //   name: 'FileUpload',
  //   component: FileUpload,
  //   meta: {layout: 'dashboard', auth: true}
  // },
  // {
    // path: "/about",
    // name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: function () {
    //   return import(/* webpackChunkName: "about" */ "../views/GentianDashboard.vue");
    // },
  // },
  {
    path: "/404",
    name: "404",
    component: PageNotFound,
    meta: {auth: false}
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // let user = JSON.parse(localStorage.getItem('logInUser'))
  if(to.meta.auth && !store.getters['accessToken']) {
    return next({name: 'SignIn'})
  }
  if(!to.meta.auth && store.getters['accessToken']) {
    if(to.fullPath == '/sign_up' || to.fullPath == '/sign_in' || to.fullPath == '/confirm_email') {
      return next({name: 'GentianDashboard'})
    }
  }
  return next()
});


export default router;
