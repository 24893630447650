<template>
    <div class="dashboard">
        <UserContent />
        <div class="page_content">
            <div class="page_header">
                <div class="page_header-title">Your Quotes</div>
                <b-button class="page_header-btn" @click="newQuote()">New Quote</b-button>
            </div>
            <div class="table_container" v-if="quotes.length">
                <b-table :items="quotes" :fields="tableFields" responsive>
                    <template v-slot:cell(date)="data">
                        <div class="">
                            {{getDate(data.item.created_at)}}
                        </div>
                    </template>
                    <template v-slot:cell(location)="data">
                        <div class="">
                            {{data.item.location}}
                        </div>
                    </template>
                    <template v-slot:cell(area)="data">
                        <div class="">
                            {{data.item.area}} m²
                        </div>
                    </template>
                    <template v-slot:cell(status)="data">
                        <div :class="{
                            'inProgress': data.item.status == '0',
                             'sent':data.item.status == '1',
                             'completed':data.item.status == '2'
                        }">
                            {{getStatus(data.item.status)}}
                        </div>
                    </template>
                    <template v-slot:cell(file)="data">
                        <div class="table_item">

                            {{data.item.file}} <a target="_blank" :href="data.item.fileUrl"><div class="download_btn"></div></a>
                        </div>
                    </template>
<!--                    <template v-slot:cell(response)="data">-->
<!--                        <div class="table_item">-->
<!--                            {{data.item.response}} <div class="download_btn"></div>-->
<!--                        </div>-->
<!--                    </template>-->
                </b-table>
            </div>
            <div class="table_container" v-else>
                <div class="table_title">Welcome on board</div>
                <div class="table_subtitle">You have no sent quotes yet․ To open a quote, please click the new quote button above</div>
            </div>

        </div>

    </div>
</template>

<script>
    import UserContent from "../components/UserContent";
    import {mapActions} from "vuex";
    export default {
        name: "GentianDashboard",
        components: {
            UserContent
        },
        data() {
            return {
                tableFields: [
                    {
                        key: 'date',
                        label: 'Opened',
                    },
                    {
                        key: 'location',
                        label: 'Location',
                    },
                    {
                        key: 'area',
                        label: 'Area',
                    },
                    {
                        key: 'status',
                        label: 'Status',
                    },
                    {
                        key: 'file',
                        label: 'File',
                    },
                    // {
                    //     key: 'response',
                    //     label: 'Response',
                    // }
                ],
                quotes: []
            }
        },
        created() {
            this.getQuotes()
            .then(res => {
                this.quotes = res.data
            })
        },
        methods: {
            ...mapActions(['getQuotes']),
            newQuote() {
                this.$router.push('/map')
                // this.$router.push('/chooseMap')
            },
            getDate(val) {
                let d = new Date(val)
                return d.toLocaleDateString('en-GB') + ' ' + d.toLocaleTimeString('en-GB', { hour: "numeric",
                    minute: "numeric"})
            },
            getStatus(ev) {
                switch (ev) {
                    case '0': return 'In Progress';
                    break;
                    case '1': return 'Sent';
                        break;
                    case '2': return 'Completed';
                        break;
                        default: return  ''
                }
            }
        }
    }
</script>
<style scoped lang="scss">
.dashboard {
    display: flex;
    padding: 40px;
    margin: 18px 0 0;
}
    .page {
        &_content {
            width: 100%;
            margin-left: 24px;
            padding: 32px 48px;
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
            border-radius: 16px;
            & .table {
                &_container {
                    min-height: 60vh;
                    padding-top: 53px;
                    ::v-deep .table {
                        border-bottom: 1px solid #dee2e6;
                        & thead {
                            background: $main_blue;
                            border-bottom: none;
                            color: #fff;
                            & th {
                                text-align: center;
                            }
                        }
                        & tbody {
                            & td {
                                text-align: center;
                            }
                        }
                    }
                    & .inProgress {
                        color: #ff9d3e;
                        font-weight: 500;
                    }
                    & .sent {
                        color: #316bff;
                        font-weight: 500;
                    }
                    & .completed {
                        color: #00b900;
                        font-weight: 500;
                    }
                    & .table_item {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    & .download_btn {
                        width: 16px;
                        height: 16px;
                        background: url("~@/assets/images/arrow_down.svg") no-repeat center;
                        background-size: cover;
                        margin-left: 5px;
                        cursor: pointer;
                    }

                }
                &_title {
                    padding: 20px 0;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 22px;
                    text-align: center;
                    color: $main_blue;
                }
                &_subtitle {
                    padding: 4px 0;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 18px;
                    text-align: center;
                    color: $dark_grey;
                }
            }
        }
        &_header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 24px;
            border-bottom: 1px solid $main_grey;
            &-title {
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                color: $dark_grey;
            }
            &-btn {
                max-width: 230px;
                width: 100%;
                background: $main_blue;
                border-radius: 360px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 11px 14px;
                color: $white;
                line-height: 19px;
                border: none;
                &:hover {
                    background: $main_hover;
                    border: none;
                }
            }
        }
    }
</style>
