import { render, staticRenderFns } from "./GentianDashboard.vue?vue&type=template&id=2f6c6508&scoped=true&"
import script from "./GentianDashboard.vue?vue&type=script&lang=js&"
export * from "./GentianDashboard.vue?vue&type=script&lang=js&"
import style0 from "./GentianDashboard.vue?vue&type=style&index=0&id=2f6c6508&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f6c6508",
  null
  
)

export default component.exports