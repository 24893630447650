<template>
    <div class="bar_container">
        <router-link to="/home">
            <div class="bar_logo"></div>
        </router-link>
        <b-button v-if="accessToken" class="bar_btn" @click="signOut()">Sign Out</b-button>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: "NavBar",
        data() {
            return {

            }
        },
        computed: {
            ...mapGetters(['accessToken'])
        },
        methods: {
            signOut() {
                this.$store.commit('clearUserData')
                this.$router.push('/')
            }
        }
    }
</script>

<style scoped lang="scss">
.bar {
    &_container {
        width: 100%;
        height: 80px;
        background: $main_blue;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 40px;
        position: sticky;
        top: 0;
        z-index: 11;
    }
    &_logo {
        width: 154px;
        height: 33px;
        background: url("../assets/images/GentianLogo_White.svg") no-repeat center;
        background-size: contain;
    }

    &_btn {
        width: 100px;
        height: 40px;
        border-radius: 360px;
        border: none;
        background: $white;
        color: $black;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        &:hover {
            color: $black;
            background: $main_hover;
        }
    }
}
</style>
