<template>
    <div class="h-100">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "Default"
    }
</script>

<style scoped>

</style>
