<template>
    <div class="page_layer">
        <NavBar />
        <div class="page_content">
            <div class="page_card">
                <div class="page_card-icon"></div>
                <div class="page_card-title">Please confim your e-mail address</div>
                <div class="page_card-info">Confirm your email address by clicking the link we
                    sent to <span class="page_card-email">{{email}}</span> </div>
                <b-button class="page_card-btn" @click="resendEmail()">Resend Email</b-button>
            </div>
        </div>
    </div>
</template>

<script>
    import NavBar from "./NavBar";
    import {mapActions} from "vuex";
    export default {
        name: "ConfirmEmail",
        components: {
            NavBar
        },
        data() {
            return {
                email: null,
                id: null
            }
        },
        created() {
            if(this.$route.query.email) {
                this.email = this.$route.query.email
                this.id = this.$route.query.id
            }
        },
        methods: {
            ...mapActions(['emailResend']),
            resendEmail() {
                let data = {
                    email: this.email,
                    id: this.id
                }
                this.emailResend(data)
                .then(res => {

                })
            }
        }
    }
</script>

<style scoped lang="scss">
.page {
    &_layer {
        height: calc(100% - 80px);
    }
    &_content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &_card {
        width: 540px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 32px 50px;
        &-icon {
            width: 64px;
            height: 64px;
            background: url("~@/assets/images/forward_email.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
        &-title {
            padding: 24px 0 12px;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            color: $dark_grey;
        }
        &-info {
            text-align: center;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: $dark_purple;
        }
        &-email {
            font-weight: 600;
            color: $main_blue;
        }
        &-btn {
            max-width: 440px;
            width: 100%;
            background: $main_blue;
            border-radius: 360px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 11px 14px;
            color: $white;
            line-height: 19px;
            margin: 24px 0 5px;
            &:hover {
                background: $main_hover;
                border: 1px solid $main_hover;
            }
        }
    }
}
</style>
