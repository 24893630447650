<template>
    <div class="user_data" :class="{'bar_visible': barStatus}">
        <div class="user_container" v-if="!editStatus">
            <div class="user_title-container">
                <div class="user_title">Personal Details</div>
                <div class="user_edit-btn" @click="editStatus = !editStatus"></div>
            </div>

            <div class="user_data-content">
                <div class="user_data-item">
                    <div class="user_data-item_subtitle">Full Name</div>
                    <div class="user_data-item_value">{{userData.name}}</div>
                </div>
                <div class="user_data-item">
                    <div class="user_data-item_subtitle">Company Name</div>
                    <div class="user_data-item_value">{{userData.companyName}}</div>
                </div>
                <div class="user_data-item">
                    <div class="user_data-item_subtitle">Email</div>
                    <div class="user_data-item_value">{{userData.email}}</div>
                </div>
                <div class="user_data-item">
                    <div class="user_data-item_subtitle">Phone Details</div>
                    <div class="user_data-item_value">{{userData.phone}}</div>
                </div>
                <div class="user_data-item">
                    <div class="user_data-item_subtitle">Password</div>
                    <div class="user_data-item_value">********</div>
                </div>
            </div>
        </div>
        <div class="user_container" v-if="editStatus">
            <div class="user_title-container">
                <div class="user_title">Edit Personal Details</div>
                <div class="user_close-btn" @click="editStatus = !editStatus"></div>
            </div>

            <div class="user_data-content">

                <div class="input_item">
                    <b-form-input
                            v-model="editData.name"
                            type="text"
                            placeholder="Name Surname"
                    ></b-form-input>
                </div>
                <div class="input_item">
                    <b-form-input
                            v-model="editData.companyName"
                            type="text"
                            placeholder="Company Name"
                    ></b-form-input>
                </div>
                <div class="input_item">
                    <b-form-input
                            v-model="editData.email"
                            type="email"
                            placeholder="Email"
                            disabled
                    ></b-form-input>
                </div>
                <div class="input_item">
                    <b-form-input
                            v-model="editData.phone"
                            type="number"
                            placeholder="Phone"
                    ></b-form-input>
                </div>
                <div class="change_pass" @click="changePass = !changePass">Change Password</div>
                <b-button class="save_btn" @click="updateProfileData()">Save Changes</b-button>

                <div class="change_pass-content" v-if="changePass">
                    <div class="input_item">
                        <b-form-input
                                v-model="passwordData.oldPassword"
                                :type="old_pass"
                                placeholder="Old Password"
                        ></b-form-input>
                        <div :class="{'eye_close': old_pass == 'password', 'eye_open': old_pass == 'text'}"
                             @click="showPassword('old_pass')"></div>
                    </div>
                    <div class="input_item">
                        <b-form-input
                                v-model="passwordData.newPassword"
                                :type="new_pass"
                                placeholder="New Password"
                        ></b-form-input>
                        <div :class="{'eye_close': new_pass == 'password', 'eye_open': new_pass == 'text',}"
                             @click="showPassword('new_pass')"></div>
                    </div>
                    <span v-if="successChange" class="success_msg">{{successChange}}</span>
                    <b-button class="save_btn" @click="changePassword()">Change Password</b-button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "UserContent",
        data() {
            return {
                userData: {},
                editStatus: false,
                changePass: false,
                old_pass: "password",
                new_pass: "password",
                editData: {
                    name: null,
                    companyName: null,
                    email: null,
                    phone: null
                },
                passwordData: {
                    oldPassword: null,
                    newPassword: null,
                },
                successChange: null,
            }
        },
        created() {
            this.getProfileData()
        },
        computed: {
          ...mapGetters(['barStatus'])
        },
        methods: {
            ...mapActions(['getUserData', 'editUser', 'passwordChange']),
            showPassword(item) {
                if (item === 'old_pass') {
                    if (this.old_pass === "password") {
                        this.old_pass = "text"
                    } else {
                        this.old_pass = "password"
                    }
                } else {
                    if (this.new_pass === "password") {
                        this.new_pass = "text"
                    } else {
                        this.new_pass = "password"
                    }
                }
            },
            getProfileData() {
                this.getUserData()
                    .then((res) => {
                        this.userData = res.data
                        this.editData.name = this.userData.name
                        this.editData.companyName = this.userData.companyName
                        this.editData.email = this.userData.email
                        this.editData.phone = this.userData.phone
                    })
            },
            updateProfileData() {
                this.editUser(this.editData)
                .then(res => {
                    if(res.status == 200) {
                        this.getProfileData()
                        this.editStatus = !this.editStatus
                    }
                })
            },
            changePassword() {
                this.successChange = null
                this.passwordChange(this.passwordData)
                .then(res => {
                    this.successChange = res.data.status
                    setTimeout(() => {
                        this.successChange = null
                        this.changePass = !this.changePass
                        this.passwordData.oldPassword = null
                        this.passwordData.newPassword = null
                    }, 3000)

                })
            }

        }
    }
</script>

<style scoped lang="scss">
.user {
    &_container {
        width: 400px;
        /*width: 100%;*/
        padding: 16px 32px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        position: sticky;
        top: 140px;
        @media screen and (max-width: 1440px) {
            width: 320px;
        }
    }
    &_title {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: $dark_grey;
        @media screen and (max-width: 1440px) {
            font-size: 18px;
        }
        &-container {
            display: flex;
            justify-content: space-between;
        }
    }
    &_edit-btn {
        width: 24px;
        height: 24px;
        background: url("~@/assets/images/edit.svg") no-repeat center;
        background-size: cover;
        cursor: pointer;
    }
    &_close-btn {
        width: 24px;
        height: 24px;
        background: url("~@/assets/images/close.svg") no-repeat center;
        background-size: cover;
        cursor: pointer;
    }
    &_data {
        /*@media screen and (max-width: 1024px) {*/
            position: absolute;
            transform: translateX(-200px);
            transition: .4s;
            visibility: hidden;
            opacity: 0;
            background: #fff;
            z-index: 10;
            border-radius: 16px;
        /*}*/
        &.bar_visible {
            transform: translateX(0);
            transition: .4s;
            visibility: visible;
            opacity: 1;
            background: #fff;
            z-index: 10;
            border-radius: 16px;
        }
        &-content {
            margin-top: 16px;
            border-top: 1px solid #BEBEBE;
            padding: 8px 0;
            & .input_item {
                padding: 8px 0;
                position: relative;
                & input {
                    background: $white;
                    border: 1px solid $main_grey;
                    border-radius: 4px;
                    &:disabled {
                        background: #BEBEBE;
                    }
                }
                & .eye_close {
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    right: 20px;
                    top: 20px;
                    background-image: url("~@/assets/images/visibility closed.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    cursor: pointer;
                }

                & .eye_open {
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    right: 20px;
                    top: 20px;
                    background: url("~@/assets/images/visibility_open.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    cursor: pointer;
                }
            }
            & .change_pass {
                padding: 8px 0;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: $main_blue;
                cursor: pointer;
            }
            & .save_btn {
                margin: 16px 0 8px;
                width: 100%;
                background: $main_blue;
                border-radius: 360px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 11px 14px;
                color: $white;
                line-height: 19px;
                border: none;
                &:hover {
                    background: $main_hover;
                    border: none;
                }
            }
        }
        &-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $dark_grey;
            padding: 8px 0;
            @media screen and (max-width: 1440px) {
                font-size: 14px;
                line-height: 20px;
            }
            &_value {
                text-align: end;
            }
        }
    }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>
