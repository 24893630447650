<template>
    <div class="upload_container">
        <UserContent />
        <div class="upload_content">
            <div class="upload_content-header">
                <div class="header_title">New Quotes</div>
                <div class="header_buttons">
                    <b-button class="header_btn open_map" @click="openMap()">Open Map</b-button>
                    <b-button class="header_btn" @click="closeQuote()">Close Quote</b-button>
                </div>
            </div>

            <div class="upload_items">
                <div class="upload_items-title">File Uploaded</div>
                <div class="upload_items-btn">
                    <input type="file" class="upload_items-input" @change="onFileChange" />Add File
                </div>

                <div class="upload_items-content">
                    <div class="upload_item" v-for="(item, index) in uploadItems" :key="index">
                        <div class="upload_item-content">
                            <div class="upload_item-icon"></div><div class="upload_item-content_text">{{item.features[0].id}}</div>
                        </div>
                        <div class="upload_item-remove_icon" @click="removeFile(index)"></div>
                    </div>

                </div>
            </div>
            <div class="send_quote-container">
                <b-button class="send_quote-btn" @click="sendQuote()">Send Quote</b-button>
            </div>
        </div>

    </div>
</template>

<script>
    import UserContent from "../components/UserContent";
    export default {
        name: "FileUpload",
        components: {
            UserContent
        },
        data() {
            return {
                uploadItems: [],
            }
        },
        methods: {
            closeQuote() {
                this.$router.push('/home')
            },
            openMap() {
                this.$router.push('/map')
            },
            sendQuote() {
                this.$router.push({ path: "/map", query: { files: this.uploadItems} } )
            },
            onFileChange(event) {
                const reader = new FileReader();
                reader.addEventListener('load', (event) => {
                    this.uploadItems.push(JSON.parse(event.target.result))
                });
                reader.readAsText(event.target.files[0]);
            },
            removeFile(id) {
                this.uploadItems.splice(id, 1);
            }
        }
    }
</script>

<style scoped lang="scss">
.upload {
    &_container {
        display: flex;
        padding: 40px;
        margin: 18px 0 0;
    }
    &_content {
        width: 100%;
        margin-left: 24px;
        padding: 32px 48px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        &-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 24px;
            border-bottom: 1px solid $main_grey;
            & .header {
                &_title {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 36px;
                    color: $dark_grey;
                }
                &_buttons {
                    display: flex;
                    align-items: center;

                }
                &_btn {
                    width: 230px;
                    background: $main_blue;
                    border-radius: 360px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 11px 14px;
                    color: $white;
                    line-height: 19px;
                    border: none;
                    &.open_map {
                        background: $white;
                        color: $main_blue;
                        border: 1px solid $main_blue;
                        margin: 0 8px;
                    }
                    &:hover {
                        background: $main_hover;
                        border: 1px solid $main_hover;
                    }
                }
            }
        }
        & .send_quote {
            &-container {
                border-top: 1px solid #BEBEBE;
                display: flex;
                justify-content: center;
                max-width: 520px;
                margin: 0 auto;
            }
            &-btn {
                margin: 24px 0 80px;
                width: 100%;
                background: $main_blue;
                border-radius: 360px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 11px 14px;
                color: $white;
                line-height: 19px;
                border: 1px solid transparent;
                &:hover {
                    background: $main_hover;
                    border: 1px solid $main_hover;
                }
            }
        }
    }
    &_items {
        display: flex;
        align-items: center;
        flex-direction: column;
        &-title {
            font-weight: 500;
            font-size: 32px;
            line-height: 48px;
            color: $dark_grey;
            padding: 80px 0 24px;
        }
        &-btn {
            width: 230px;
            border-radius: 360px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 11px 14px;
            line-height: 19px;
            background: $white;
            color: $main_blue;
            border: 1px solid $main_blue;
            margin: 0 8px;
            position: relative;
            &:hover {
                background: $main_hover;
                border: 1px solid $main_hover;
            }
        }
        &-input {
            opacity: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            cursor: pointer;
        }
        &-content {
            padding: 60px 0;
            max-width: 520px;
            width: 100%;

        }
    }
    &_item {
        width: 100%;
        height: 44px;
        border: 1px solid #BEBEBE;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;
        margin: 10px 0;
        &-content {
            display: flex;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: $main_blue;
            &_text {
                text-overflow: ellipsis;
                overflow: hidden;
                width: 300px;
                height: 1.2em;
                white-space: nowrap;
            }
        }
        &-icon {
            margin: 0 12px;
            width: 24px;
            height: 24px;
            background-image: url("~@/assets/images/paperClip.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
        &-remove_icon {
            margin: 0 12px;
            width: 24px;
            height: 24px;
            background-image: url("~@/assets/images/trash.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            cursor: pointer;
        }
    }
}
</style>
