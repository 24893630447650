<template>
    <div class="choose">
        <UserContent />
        <div class="choose_content">
            <div class="choose_content-header">
                <div class="header_title">New Quote</div>
                <b-button class="header_btn" @click="closeQuote()">Close Quote</b-button>
            </div>

            <div class="content_container">
                <div class="content_container-title">Select either upload file or select AOI on a map</div>
                <div class="content_container-buttons">
                    <b-button class="content_container-btn" @click="fileUpload()">Upload file</b-button>
                    <b-button class="content_container-btn" @click="toMap()">Open the map</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import UserContent from "../components/UserContent";
    export default {
        name: "ChooseMap",
        data() {
            return {

            }
        },
        components: {
            UserContent
        },
        methods: {
            closeQuote() {
                this.$router.push('/home')
            },
            toMap() {
                this.$router.push('/map')
            },
            fileUpload() {
                this.$router.push('/fileUpload')
            }
        }
    }
</script>

<style scoped lang="scss">
    .choose {
        display: flex;
        padding: 40px;
        margin: 18px 0 0;
        &_content {
            width: 100%;
            margin-left: 24px;
            padding: 32px 48px;
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
            border-radius: 16px;
            &-header {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 24px;
                border-bottom: 1px solid $main_grey;
                & .header {
                    &_title {
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 36px;
                        color: $dark_grey;
                    }
                    &_buttons {
                        display: flex;
                        align-items: center;

                    }
                    &_btn {
                        width: 230px;
                        background: $main_blue;
                        border-radius: 360px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 11px 14px;
                        color: $white;
                        line-height: 19px;
                        border: 1px solid $main_blue;
                        &:hover {
                            background: $main_hover;
                            border: 1px solid $main_hover;
                        }
                    }
                }
            }
            & .content {
                &_container {
                    padding-top: 50px;
                    &-title {
                        font-weight: 500;
                        font-size: 32px;
                        line-height: 48px;
                        text-align: center;
                        color: $dark_grey;
                        margin: 30px 0;
                    }
                    &-buttons {
                        display: flex;
                        justify-content: center;
                        padding: 50px 0;
                    }
                    &-btn {
                        background: transparent;
                        border: 1px solid $main_hover;
                        border-radius: 16px;
                        color: $dark_grey;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 150%;
                        width: 320px;
                        height: 200px;
                        margin: 0 10px;
                        &:focus {
                            box-shadow: none;
                        }
                        &:active {
                            background-color: $main_hover !important;
                            border-color: $main_hover !important;
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }

</style>
