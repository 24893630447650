<template>
    <div class="map_page">
        <div class="barMenu" @click="changeStatus()">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
            </svg>
        </div>
        <UserContent />
        <div class="map_content">
            <div class="map_content-header">
                <div class="header_title">New Quotes</div>
                <div class="header_buttons">
<!--                    <b-button class="header_btn download" id="up-btn" @click="uploadFile()">Upload File</b-button>-->
                </div>
            </div>

            <div class="map">
                <div class="map_title">To obtain a quote, please select your area of interest</div>
                <div class="map_subtitle">To obtain a quote, please select your area of interest.
                    Use the drawing tools or upload a shapefile or KML/KMZ file.</div>
                <Map @mapData="mapData" @uploadFile="uploadFile" :uploadedFile="uploadedFile" ref="map_component"/>
                <span v-if="errorMsg" class="text_danger">{{errorMsg}}</span>
                <b-button class="map_btn" id="map-btn" @click="sendMapData()">Send Quote</b-button>
                <div v-if="successMessage" class="success_message">{{successMessage}}</div>
            </div>

            <div class="map_content-header">
                <div class="header_title">Your Quotes</div>
                <div class="header_buttons">
                </div>
            </div>
            <div class="table_container" v-if="quotes.length">
                <b-table :items="quotes" :fields="tableFields" responsive>
                    <template v-slot:cell(date)="data">
                        <div class="item_date">
                            {{getDate(data.item.created_at)}}
                        </div>
                    </template>
                    <template v-slot:cell(location)="data">
                        <div class="">
                            {{data.item.location}}
                        </div>
                    </template>
                    <template v-slot:cell(area)="data">
                        <div class="">
                            {{data.item.area}} m²
                        </div>
                    </template>
                    <template v-slot:cell(status)="data">
                        <div :class="{
                            'inProgress': data.item.status == '0',
                             'sent':data.item.status == '1',
                             'completed':data.item.status == '2'
                        }">
                            {{getStatus(data.item.status)}}
                        </div>
                    </template>
                    <template v-slot:cell(file)="data">
                        <div class="table_item-container" :id="`tooltip-target-${data.index}`">
                            <div class="table_item">
                                {{data.item.file}}
                            </div>
                            <a target="_blank" :href="data.item.fileUrl"><div class="download_btn"></div></a>
                            <b-tooltip :target="`tooltip-target-${data.index}`" triggers="hover" placement="rightbottom">
                                {{data.item.file}}
                            </b-tooltip>
                        </div>
                    </template>
                </b-table>
            </div>
            <div class="table_container" v-else>
                <div class="table_title">Welcome on board</div>
                <div class="table_subtitle">You have no sent quotes yet․ To open a quote, please click the new quote button above</div>
            </div>
        </div>
        <!--forgot modal-->
        <b-modal ref="success-modal" size="md" hide-header hide-footer>
            <!--            <div class="close_btn_content" @click="forgotPassword()">-->
            <!--                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#013F78" class="bi bi-x-lg"-->
            <!--                     viewBox="0 0 16 16">-->
            <!--                    <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>-->
            <!--                </svg>-->
            <!--            </div>-->
            <div class="modal_content">
                <div class="modal_icon"></div>
                <div class="modal_title">
                    Your Quote Successfully Sent
                </div>
                <div class="modal_subtitle">
                    We will be in touch with you soon.
                </div>
                <div class="modal_btn">
                    <b-button class="ok_btn" @click="modalToggle()">Ok</b-button>
                </div>

            </div>

        </b-modal>
        <!--forgot modal-->

        <!--Upload modal-->
        <b-modal ref="upload-modal" size="lg" hide-header hide-footer>
            <div class="dd">
                <div class="close_btn_content" @click="uploadFile()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#013F78" class="bi bi-x-lg"
                         viewBox="0 0 16 16">
                        <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
                    </svg>
                </div>
                <div class="modal_content file_modal">
                    <div class="upload_items">
                        <div class="upload_items-title">Upload Shapefile /KMZ/KML</div>
                        <p>Please select either:</p>
                        <h3 >Zipped Shapefile archive</h3>
                        <p class="upload_items-sub">(containing your .shp, .shx, .dbf and optional .prj files)</p>
                        <div>
                            <h5>KMZ file (.kmz)</h5>
                            <h5>KML file (.kml)</h5>
                        </div>
                        <div class="upload_items-btn">
                            <input type="file" class="upload_items-input" @change="onFileChange" ref="file_upload" />Select File
                        </div>
                        <div class="upload_items-content">
                            <div class="upload_item" v-for="(item, index) in uploadItems" :key="index">
                                <div class="upload_item-content">
                                    <div class="upload_item-icon"></div><div class="upload_item-content_text">{{item.features[0].id}}</div>
                                </div>
                                <div class="upload_item-remove_icon" @click="removeFile(index, 'json')"></div>
                            </div>
                            <div class="upload_item" v-if="fileData">
                                <div class="upload_item-content">
                                    <div class="upload_item-icon"></div><div class="upload_item-content_text">{{fileData.name}}</div>
                                </div>
                                <div class="upload_item-remove_icon" @click="removeFile(fileData, 'kml')"></div>
                            </div>
                            <div class="upload_item" v-if="upFile">
                                <div class="upload_item-content">
                                    <div class="upload_item-icon"></div><div class="upload_item-content_text">{{upFile.fileName}}</div>
                                </div>
                                <div class="upload_item-remove_icon" @click="removeFile(upFile, 'upFile')"></div>
                            </div>

                        </div>
                    </div>


                    <div class="send_quote-container">
                        <b-button class="send_quote-btn" @click="sendQuote()">Upload File</b-button>
                    </div>
<!--                    <div class="modal_icon"></div>-->
<!--                    <div class="modal_title">-->
<!--                        Your Quote Successfully Sent-->
<!--                    </div>-->
<!--                    <div class="modal_subtitle">-->
<!--                        We will be in touch with you soon.-->
<!--                    </div>-->
<!--                    <div class="modal_btn">-->
                        <!--                    <b-button class="ok_btn" @click="modalToggle()">Ok</b-button>-->
<!--                    </div>-->
                </div>
            </div>


        </b-modal>
        <!--Upload modal-->
    </div>
</template>

<script>
    import UserContent from "../components/UserContent";
    import Map from "../components/Map";
    import {mapActions} from "vuex";
    import tj from 'togeojson'
    // import fs from 'fs'
    import  axios from "axios";
    import shp from 'shpjs';

    export default {
        name: "MapPage",
        data() {
            return {
                accessToken: 'sk.eyJ1Ijoic2hvd21lY2FyaWJiZWFuIiwiYSI6ImNsZThleGFpMjBldDkzd2tiY2g1dDI2bHMifQ.b7VyXatanFj3DruF2ZVoRQ', // your access token. Needed if you using Mapbox maps
                mapSendData: null,
                uploadedFile: null,
                successMessage: null,
                errorMsg: null,
                uploadItems: [],
                tableFields: [
                    {
                        key: 'date',
                        label: 'Opened',
                    },
                    {
                        key: 'location',
                        label: 'Location',
                    },
                    {
                        key: 'area',
                        label: 'Area',
                    },
                    {
                        key: 'status',
                        label: 'Status',
                    },
                    {
                        key: 'file',
                        label: 'File',
                    },
                    // {
                    //     key: 'response',
                    //     label: 'Response',
                    // }
                ],
                quotes: [],
                fileData: null,
                upFile: null,
                geojson: null,
                rounded_area: null,
            }
        },
        components: {
            UserContent,
            Map
        },
        created() {
            this.getQuotes()
                .then(res => {
                    this.quotes = res.data
                })
            // if (this.$route.query.files !== '[object Object]' && this.$route.query.files !== undefined) {
            //     this.$route.query.files.forEach(item => {
            //         this.uploadedFile = JSON.stringify(item)
            //         localStorage.setItem('fileUploaded', this.uploadedFile)
            //     })
            //
            // } else if (this.$route.query.files == undefined) {
            //     this.uploadedFile = null
            // } else {
            //     this.uploadedFile = JSON.parse(localStorage.getItem('fileUploaded'))
            // }
            this.uploadedFile = JSON.parse(localStorage.getItem('fileUploaded'))

            // this.mapSendData = null;
        },
        mounted() {
            if(this.uploadedFile) {
                setTimeout(() => {
                    this.$refs.map_component.readFile()
                }, 1000)
            }


            // const kmlFileUrl = 'http://example.com/foo.kml'
            //
            //
            // axios.get(kmlFileUrl)
            //     .then(response => {
            //         const kml = new DOMParser().parseFromString(response.data)
            //         this.geojson = tj.kml(kml)
            //         this.geojsonWithStyles = tj.kml(kml, { styles: true })
            //     })
            //     .catch(error => {
            //         console.error('Error loading KML file:', error)
            //     })
        },
        methods: {
            ...mapActions(['storeQuote', 'getQuotes']),
            mapData(ev) {
                this.mapSendData = ev
            },
            changeStatus() {
                this.$store.commit('changeBarStatus', true)
            },
            sendMapData() {
                this.errorMsg = null
                if(this.mapSendData == null) {
                    this.errorMsg = 'Nothing to send.'
                } else {
                    this.storeQuote(this.mapSendData)
                        .then(res => {
                            if(res) {
                                this.modalToggle()
                                this.getQuotes()
                                    .then(res => {
                                        this.quotes = res.data
                                    })
                                //     this.successMessage = res.data.message
                                //     setTimeout(() => {
                                //         this.successMessage = null
                                //         this.$router.push('/home')
                                //     }, 5000)
                            }
                        })
                }
            },
            modalToggle() {
                this.$refs['success-modal'].toggle('#map-btn')
            },
            uploadFile() {
                this.$refs['upload-modal'].toggle('#up-btn')
            },
             onFileChange(event) {
                const files =  event.target.files;
                const allowedExtensions = ["kml"];
                for (let i = 0; i < files.length; i++) {
                    const extension = files[i].name
                        .substr(files[i].name.lastIndexOf(".") + 1)
                        .toLowerCase();
                    if (allowedExtensions.indexOf(extension) !== -1) {
                        this.fileData = event.target.files[0]

                    } else if(extension == 'zip') {
                        const reader = new FileReader();
                        reader.addEventListener('load', (event) => {
                             shp.parseZip(event.target.result).then((data) => {
                                 this.upFile = data
                            });
                        });
                        reader.readAsArrayBuffer(files[i]);
                    } else {
                        const reader = new FileReader();
                        reader.addEventListener('load', (event) => {
                            this.uploadItems.push(JSON.parse(event.target.result))
                        });
                        reader.readAsText(event.target.files[0]);
                        this.$refs.file_upload.value = "";
                    }
                }

            },
            removeFile(id, type) {
                if(type == 'json' ) {
                    this.uploadItems.splice(id, 1);
                    this.uploadItems = []
                } else if (type == 'kml' ) {
                    this.fileData = null
                } else if(type == 'upFile') {
                    this.upFile = null
                }

            },
            sendQuote() {
                this.uploadFile()
                if (this.uploadItems.length) {
                    this.uploadItems.forEach(item => {
                        this.uploadedFile = JSON.stringify(item)
                        localStorage.setItem('fileUploaded', this.uploadedFile)
                    })
                    this.uploadedFile = JSON.parse(localStorage.getItem('fileUploaded'))
                    setTimeout(() => {
                        this.$refs.map_component.readFile()
                        this.uploadItems = []
                    }, 500)
                    this.mapSendData = null;
                } else if(this.upFile) {
                    localStorage.setItem('fileUploaded', JSON.stringify(this.upFile))
                    this.uploadedFile = JSON.parse(localStorage.getItem('fileUploaded'))
                    setTimeout(() => {
                        this.$refs.map_component.readFile()
                        this.uploadItems = []
                    }, 500)
                    this.mapSendData = null;
                } else {
                    const reader = new FileReader()
                    reader.onload = () => {
                        const kml = new DOMParser().parseFromString(reader.result, 'text/xml')
                        this.geojson = tj.kml(kml)
                        const area = turf.area(this.geojson);
                        const km_area = (Math.round(area * 100) / 100);
                        this.rounded_area = km_area.toFixed(2) ;
                        this.$refs.map_component.rounded_area = this.rounded_area

                        this.uploadedFile = JSON.stringify(this.geojson)
                        localStorage.setItem('fileUploaded', this.uploadedFile)
                        this.uploadedFile = JSON.parse(localStorage.getItem('fileUploaded'))
                        setTimeout(() => {
                            this.$refs.map_component.readFile()
                            this.$refs.map_component.setArea()
                            this.uploadItems = []
                        }, 500)
                        this.mapSendData = null;
                    }
                    reader.readAsText(this.fileData)
                    this.fileData = null
                }

            },
            getDate(val) {
                let d = new Date(val)
                return d.toLocaleDateString('en-GB') + ' ' + d.toLocaleTimeString('en-GB', { hour: "numeric",
                    minute: "numeric"})
            },
            getStatus(ev) {
                switch (ev) {
                    case '0': return 'In Progress';
                        break;
                    case '1': return 'Sent';
                        break;
                    case '2': return 'Completed';
                        break;
                    default: return  ''
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .map_page {
        display: flex;
        padding: 40px;
        margin: 18px 0 0;
        position: relative;
        & .barMenu {
            position: absolute;
            top: 0;
            display: block;
            cursor: pointer;
            /*@media screen and (max-width: 1024px) {*/
            /*    display: block;*/
            /*}*/
        }
    }

    .map {
        display: flex;
        flex-direction: column;
        align-items: center;
        /*padding-top: 20px;*/

        & .success_message {
            margin: 15px 0;
            color: green;
            font-weight: 600;
        }

        &_content {
            width: 100%;
            margin-left: 24px;
            padding: 32px 48px;
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
            border-radius: 16px;
            @media screen and (max-width: 1440px) {
                padding: 20px 40px;
            }
            & .table {
                &_container {
                    min-height: 60vh;
                    padding-top: 53px;
                    ::v-deep .table {
                        border-bottom: 1px solid #dee2e6;
                        & thead {
                            background: $main_blue;
                            border-bottom: none;
                            color: #fff;
                            @media screen and (max-width: 1440px) {
                                font-size: 15px;
                            }
                            & th {
                                text-align: center;
                            }
                        }
                        & tbody {
                            @media screen and (max-width: 1440px) {
                                font-size: 14px;
                            }
                            & td {
                                text-align: center;
                                @media screen and (max-width: 1280px) {
                                    max-width: 200px;
                                }
                            }
                        }
                    }
                    & .inProgress {
                        color: #ff9d3e;
                        font-weight: 500;
                    }
                    & .sent {
                        color: #316bff;
                        font-weight: 500;
                    }
                    & .completed {
                        color: #00b900;
                        font-weight: 500;
                    }
                    & .table_item {
                        display: block;
                        /*justify-content: center;*/
                        /*align-items: center;*/
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 200px;
                        /*height: 1.2em;*/
                        white-space: nowrap;
                        @media screen and (max-width: 1440px) {
                            width: 180px;
                        }
                        &-container {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                    & .download_btn {
                        width: 16px;
                        height: 16px;
                        background: url("~@/assets/images/arrow_down.svg") no-repeat center;
                        background-size: cover;
                        margin-left: 5px;
                        cursor: pointer;
                    }

                }
                &_title {
                    padding: 20px 0;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 22px;
                    text-align: center;
                    color: $main_blue;
                }
                &_subtitle {
                    padding: 4px 0;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 18px;
                    text-align: center;
                    color: $dark_grey;
                }
            }

            &-header {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 24px;
                border-bottom: 1px solid $main_grey;

                & .header {
                    &_title {
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 36px;
                        color: $dark_grey;
                        @media screen and (max-width: 1440px) {
                            font-size: 22px;
                        }
                    }

                    &_buttons {
                        display: flex;
                        align-items: center;

                    }

                }
            }
        }

        &_title {
            text-align: center;
            padding: 30px 0 16px;
            font-weight: 500;
            font-size: 32px;
            line-height: 48px;
            color: $dark_grey;
            @media screen and (max-width: 1440px) {
                padding: 24px 0 14px;
                font-size: 30px;
                line-height: 40px;
            }

        }

        &_subtitle {
            text-align: center;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: $dark_grey;
            padding-bottom: 8px;
            @media screen and (max-width: 1440px) {
                padding-bottom: 0;
                font-size: 18px;
                line-height: 24px;
            }
            @media screen and (max-width: 1280px) {
                max-width: 560px;
            }
        }

        &-container {
            margin: 30px 0;
        }

        &_btn {
            text-align: center;
            width: 520px;
            background: $main_blue;
            border-radius: 360px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 11px 14px;
            color: $white;
            line-height: 19px;
            border: 1px solid transparent;
            margin: 15px 0 25px;
            &:active,
            &:focus {
                background: $main_blue;
            }

            &:hover {
                background: $main_hover;
                border: 1px solid $main_hover;
            }
            @media screen and (max-width: 1440px) {
                width: 400px;
            }

        }
    }

    /*    */
    .modal {
        &_content {
            padding: 15px;
            border: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            &.file_modal {
                & .upload {
                    &_items {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        &-title {
                            font-weight: 500;
                            font-size: 32px;
                            line-height: 48px;
                            color: $dark_grey;
                            padding: 40px 0 20px;
                        }
                        &-sub {
                            max-width: 500px;
                            text-align: center;
                        }
                        &-btn {
                            width: 230px;
                            border-radius: 360px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            padding: 11px 14px;
                            line-height: 19px;
                            background: $white;
                            color: $main_blue;
                            border: 1px solid $main_blue;
                            margin: 20px 8px;
                            position: relative;
                            &:hover {
                                background: $main_hover;
                                border: 1px solid $main_hover;
                            }
                        }
                        &-input {
                            opacity: 0;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            cursor: pointer;
                        }
                        &-content {
                            padding: 60px 0;
                            max-width: 520px;
                            width: 100%;

                        }
                    }
                    &_item {
                        width: 100%;
                        height: 44px;
                        border: 1px solid #BEBEBE;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-radius: 4px;
                        margin: 10px 0;
                        &-content {
                            display: flex;
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 24px;
                            color: $main_blue;
                            &_text {
                                text-overflow: ellipsis;
                                overflow: hidden;
                                width: 300px;
                                height: 1.2em;
                                white-space: nowrap;
                            }
                        }
                        &-icon {
                            margin: 0 12px;
                            width: 24px;
                            height: 24px;
                            background-image: url("~@/assets/images/paperClip.svg");
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                        }
                        &-remove_icon {
                            margin: 0 12px;
                            width: 24px;
                            height: 24px;
                            background-image: url("~@/assets/images/trash.svg");
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            cursor: pointer;
                        }
                    }
                }
                & .send_quote {
                    &-container {
                        border-top: 1px solid #BEBEBE;
                        display: flex;
                        justify-content: center;
                        max-width: 520px;
                        margin: 0 auto;
                    }
                    &-btn {
                        margin: 24px 0 80px;
                        width: 400px;
                        background: $main_blue;
                        border-radius: 360px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 11px 14px;
                        color: $white;
                        line-height: 19px;
                        border: 1px solid transparent;
                        &:hover {
                            background: $main_hover;
                            border: 1px solid $main_hover;
                        }
                    }
                }
            }
        }

        &_icon {
            width: 64px;
            height: 64px;
            background: url("~@/assets/images/success.svg");
        }

        &_title {
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            color: $dark_grey;
            padding: 24px 0 12px;
        }

        &_subtitle {
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: #200F31;
        }

        &_btn {
            padding-top: 24px;
            width: 100%;
            display: flex;
            justify-content: center;

            & .ok_btn {
                background: $main_blue;
                width: 90%;
                height: 44px;
                border-radius: 360px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 11px 14px;
                color: $white;
                line-height: 19px;

                &:hover {
                    background: $main_hover;
                    border: 1px solid $main_hover;
                }
            }
        }
    }
    .close_btn_content {
        display: flex;
        justify-content: end;
        & svg {
            cursor: pointer;
        }

    }

    ::v-deep .modal-content {
        border: none;
        @media screen and (max-width: 1280px) {
            width: 600px;
            margin: 0 auto;
        }
    }
</style>
