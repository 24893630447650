import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        baseURL: process.env.VUE_APP_BASE_URL,
        access_token: localStorage.getItem('access_token') || '',
        logInUser: {},
        info: {},
        bar_status: false
    },
    mutations: {
        clearUserData: function (state) {
            state.access_token = "";
            state.logInUser = {}
            localStorage.removeItem('access_token')
            localStorage.removeItem('logInUser')
        },
        setAccessToken: function (state, accessToken) {
            state.access_token = accessToken;
            localStorage.setItem('access_token', accessToken)
        },
        addUserData: (state, data) => {
            state.logInUser = data
            state.info = data
        },
        changeBarStatus: (state, data) => {
            if(state.bar_status) {
                state.bar_status = false
            } else {
                state.bar_status = data
            }

        }
    },
    actions: {

        registerUser({state, commit}, data) {
            return axios.post(`${state.baseURL}register`, {
                    name: data.fullName,
                    companyName: data.companyName,
                    email: data.email,
                    phone: data.phone,
                    password: data.password,
                    c_password: data.password_confirmation,
                }
            )
                .then((response) => {
                    if (response) {
                        // let access_token = response.data.token
                        // localStorage.setItem('access_token', access_token)
                        // state.access_token = access_token
                        // state.logInUser = response.data.user
                        // let user = JSON.stringify(response.data.user)
                        // localStorage.setItem('logInUser', user)
                        // commit('addUserData', user)
                        return response
                    }
                })
                .catch((error) => {
                    return error
                })
        },
        login({state, commit}, data) {
            return axios.post(`${state.baseURL}login`, {
                email: data.email,
                password: data.password,
            })
                .then((response) => {
                    if (response) {
                        let access_token = response.data.user.token
                        localStorage.setItem('access_token', access_token)
                        state.access_token = access_token
                        let userData = {
                            companyName: response.data.user.companyName,
                            email: response.data.user.email,
                            name: response.data.user.name,
                            phone: response.data.user.phone,
                        }
                        localStorage.setItem('logInUser', JSON.stringify(userData))
                        commit('addUserData', userData)
                        return response
                    }
                })
                .catch((error) => {
                    console.log(error)
                    return error
                })
        },
        emailResend({state}, data) {
          return axios.post(`${state.baseURL}resend-email/${data.id}`, {
              email: data.email
          })
              .then(response => {
                  return response
              })
              .catch(error => {
                  return error
              })
        },
        sendForgot({state}, data) {
            return axios.post(`${state.baseURL}password/email`, {
                email: data
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        resetPassword({state}, data) {
            return axios.post(`${state.baseURL}password/reset`, {
                email: data.email,
                token: data.token,
                password: data.password,
                password_confirmation: data.password_confirmation
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getUserData({state}) {
            return axios.get(`${state.baseURL}get-profile`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        editUser({state}, data) {
            return axios.put(`${state.baseURL}edit-profile`, {
                name: data.name,
                companyName: data.companyName,
                phone: data.phone,
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        passwordChange({state}, data) {
            return axios.put(`${state.baseURL}update-password`, {
                old_password: data.oldPassword,
                new_password: data.newPassword
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        storeQuote({state}, data) {
            const customConfig = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            return axios.post(`${state.baseURL}store-quote`, {
                area: data.area,
                mapData: data.mapData,
                location: data.location
            }, customConfig)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        getQuotes({state}) {
            return axios.get(`${state.baseURL}get-quotes`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        }
    },
    getters: {
        accessToken: state => state.access_token,
        logUserData: state => state.logInUser,
        barStatus: state => state.bar_status,
    },
});
