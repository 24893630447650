import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Dashboard from "./components/layoautes/Dashboard";
import Default from "./components/layoautes/Default";
import Vuelidate from "vuelidate"
import interceptorsSetup from '@/helpers/interceptors'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueMapbox from "vue-mapbox";
import Mapbox from "mapbox-gl";


Vue.use(VueMapbox, { mapboxgl: Mapbox });

interceptorsSetup()
Vue.component('dashboard-layout', Dashboard);
Vue.component('default-layout', Default);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuelidate)


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
