<template>
    <div class="page_layer">
        <NavBar />
        <div class="page_content">
            <div class="page_card">
                <div class="card_title">Forgot password</div>
                <form @submit.prevent="forgotPassword()">
                    <div class="input_item">
                        <label>Email</label>
                        <b-form-input
                                id="email"
                                v-model="forgot.email"
                                disabled
                                type="email"
                                trim
                        >
                        </b-form-input>
                    </div>
                    <div class="forgot_text">
                        Set the new password for your account so you can login and access all the features.
                    </div>
                    <div class="input_item">
                        <label>New Password</label>
                        <b-form-input
                                id="password"
                                placeholder="Type new password"
                                :type="r_pass"
                                v-model="forgot.password"
                                trim
                        ></b-form-input>
                        <div class="eye_close"
                             @click="showPassword('r_pass')"></div>
                    </div>

                    <div class="input_item">
                        <label>Confirm Password</label>
                        <b-form-input
                                id="password"
                                placeholder="Type new password"
                                :type="r_confirm"
                                v-model="forgot.password_confirmation"
                                trim
                        ></b-form-input>
                        <div class="eye_close" @click="showPassword('r_confirm')"></div>
                    </div>
                    <div v-if="successMsg" class="success_msg">{{successMsg}}</div>
                    <span v-if="errors['password']" class="text_danger mt-1">{{errors['password'][0]}}</span>
                    <b-button class="sign_btn" type="submit">Reset Password</b-button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import NavBar from "../NavBar";
    import {mapActions} from "vuex";
    export default {
        name: "ForgotPassword",
        components: {
            NavBar
        },
        data() {
            return {
                forgot: {
                    token: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                },
                r_pass: 'password',
                r_confirm: 'password',
                successMsg: null,
                errors: []
            }
        },
        mounted() {
            this.forgot.email = this.$route.query.email
            this.forgot.token = this.$route.query.token

        },
        methods: {
            ...mapActions(['resetPassword']),
            showPassword(item) {
                if(item === 'r_pass') {
                    if(this.r_pass === 'password') {
                        this.r_pass = 'text'
                    } else {
                        this.r_pass = 'password'
                    }
                } else {
                    if(this.r_confirm === 'password') {
                        this.r_confirm = 'text'
                    } else {
                        this.r_confirm = 'password'
                    }

                }
            },
            forgotPassword() {
                this.errors = []
                this.resetPassword(this.forgot)
                    .then(res => {
                        if(res.status === 200) {
                            this.successMsg = res.data
                            setTimeout(() => {
                                this.successMsg = ''
                                this.$router.push('/sign_in')
                            }, 4000)

                        } else {
                            this.errors = res.response.data.errors
                        }
                    })
            }
        }
    }
</script>

<style scoped lang="scss">
    .page {
        &_layer {
            height: calc(100% - 80px);
        }
        &_content {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

        }
        &_card {
            width: 780px;
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
            border-radius: 16px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 40px 0;
            & .card {
                &_title {
                    font-weight: 500;
                    font-size: 32px;
                    line-height: 48px;
                    margin-bottom: 24px;
                }
            }
            & form {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                & .forgot_text {
                    max-width: 440px;
                    padding: 10px 0 20px;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 26px;
                    color: $main_blue;
                }
                & .success_msg {
                    color: green;
                    font-size: 18px;
                    font-weight: 600;
                    margin-top: 10px;
                }

                & .sign_btn {
                    max-width: 440px;
                    width: 100%;
                    background: $main_blue;
                    border-radius: 360px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 11px 14px;
                    color: $white;
                    line-height: 19px;
                    margin: 22px 0;
                    &:hover {
                        background: $main_hover;
                        border: 1px solid $main_hover;
                    }
                }
            }
            & .input_item {
                position: relative;
                max-width: 440px;
                width: 100%;
                margin-bottom: 10px;

                & .eye_close {
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    right: 15px;
                    top: 44px;
                    background-image: url("~@/assets/images/visibility closed.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    cursor: pointer;
                }

                & .eye_open {
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    right: 15px;
                    top: 44px;
                    background: url("~@/assets/images/visibility_open.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    cursor: pointer;
                }
                & label {
                    color: $black;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                }

                & input {
                    background: $white 0 0 no-repeat padding-box;
                    border: 1px solid $main_grey;
                    border-radius: 4px;
                    max-width: 440px;
                    width: 100%;
                    height: 44px;
                    &:focus {
                        box-shadow: none;
                        border: 1px solid $dark_grey;
                        border-radius: 4px;
                    }
                }
            }
        }
    }
</style>
