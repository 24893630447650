<template>
    <div class="layer_container">
        <NavBar/>
        <slot></slot>
    </div>
</template>

<script>
    import NavBar from "../NavBar";
    export default {
        name: "Dashboard",
        components: {
            NavBar
        }
    }
</script>

<style scoped lang="scss">
.layer {
    &_container {
        display: flex;
        flex-direction: column;
    }
}
</style>
