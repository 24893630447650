import axios from 'axios';
import store from '@/store';

export default function setup() {

    // Request interceptor
    axios.interceptors.request.use(request => {
            const token = store.getters.accessToken;
            if (token) {
                request.headers.Authorization = `Bearer ${token}`;
            }
            return request
        },
        function (error) {
            return Promise.reject(error);
        })

    axios.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        async function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            const originalRequest = error.config;
            if (error.response) {
                if (error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true
                    try {
                        return axios(originalRequest)
                    } catch (_error) {
                        if (_error.response && _error.response.data) {
                            return Promise.reject(_error.response.data);
                        }
                    }
                }
                if (error.response.status === 403 && error.response.data) {
                    return Promise.reject(error.response.data);
                }
            }
            return Promise.reject(error);
        }
    );
}
